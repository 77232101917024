import React from 'react';
import Input from './Input';
import Navigation from './Navigation';

function App() {
  const hasCookie = document.cookie !== '';

  return (
    <div>
      {hasCookie ? <Navigation /> : <Input />}
    </div>
  );
}

export default App;

