import myImage from "./Datewaya.png";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; // Обратите внимание на добавление Routes
import HomeContent from "./HomeContent";
import AnketsContent from "./AnketsContent";
import MessageContent from "./MessageContent";
import PartyContent from "./PartyContent";
import "./index.css";
import axios from "axios"; // Импорт Axios
import Cookies from "js-cookie";

function Navigation() {
  const [stateFromLocation, setStateFromLocation] = useState("");
  const id = JSON.parse(Cookies.get("Cookie")).id;
  const sendCoordinatesToServer = async (latitude, longitude) => {
    try {
      const response = await axios.post("coords.php", {
        latitude,
        longitude,
        id,
      });
      if (response.status === 200) {
        console.log("Координаты успешно отправлены на сервер");
      } else {
        console.error("Ошибка: неправильный статус ответа от сервера");
      }
    } catch (error) {
      console.error("Ошибка при отправке координат на сервер:", error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Широта:", latitude);
          console.log("Долгота:", longitude);

          // Отправка координат на сервер
          sendCoordinatesToServer(latitude, longitude);

          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            setStateFromLocation(response.data.address.state);
            console.log(
              "Информация о местоположении:",
              response.data.address.state
            );
          } catch (error) {
            console.error(
              "Ошибка при получении информации о местоположении:",
              error
            );
          }
        },
        (error) => {
          console.error("Ошибка получения геолокации:", error);
        }
      );
    } else {
      console.error("Геолокация не поддерживается в этом браузере.");
    }
  }, []);
  return (
    <Router>
      <nav className="bg-gradient-to-r from-purple-500 to-pink-500 shadow-lg">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <div className="flex items-center space-x-2">
                <div>
                  <img src={myImage} alt="Логотип" className="mb-2" />
                </div>
              </div>
            </Link>

            {/* Контейнер для кнопки и выпадающего списка */}
            <div className="relative md:hidden">
              <button
                onClick={() => {
                  const mobileMenu = document.getElementById("mobile-menu");
                  mobileMenu.classList.toggle("hidden");
                }}
                className="text-white text-xl hover:text-purple-300 transition"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>

              {/* Мобильное меню */}
              <ul
                id="mobile-menu"
                className="hidden absolute top-12 right-0 mt-2 py-2 px-4 bg-white shadow-md rounded-lg"
              >
                <li>
                  <Link
                    to="/"
                    className="text-gray-800 hover:text-purple-300 transition text-xl"
                  >
                    Главная
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ankets"
                    className="text-gray-800 hover:text-purple-300 transition text-xl"
                  >
                    Анкеты
                  </Link>
                </li>
                <li>
                  <Link
                    to="/messages"
                    className="text-gray-800 hover:text-purple-300 transition text-xl"
                  >
                    Сообщения
                  </Link>
                </li>
                <li>
                  <Link
                    to="/party"
                    className="text-gray-800 hover:text-purple-300 transition text-xl"
                  >
                    Тусовка
                  </Link>
                </li>
              </ul>
            </div>

            {/* Меню для больших экранов */}
            <ul className="hidden md:flex space-x-8">
              <li>
                <Link
                  to="/"
                  className="text-white hover:text-purple-300 transition text-xl"
                >
                  Главная
                </Link>
              </li>
              <li>
                <Link
                  to="/ankets"
                  className="text-white text-xl hover:text-purple-300 transition"
                >
                  Анкеты
                </Link>
              </li>
              <li>
                <Link
                  to="/messages"
                  className="text-white text-xl hover:text-purple-300 transition"
                >
                  Сообщения
                </Link>
              </li>
              <li>
                <Link
                  to="/party"
                  className="text-white text-xl hover:text-purple-300 transition"
                >
                  Тусовка
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Routes>
        <Route
          path="/"
          element={<HomeContent locationState={stateFromLocation} />}
        />
        <Route path="/ankets" element={<AnketsContent />} />
        <Route path="/messages" element={<MessageContent />} />
        <Route path="/party" element={<PartyContent />} />
      </Routes>
    </Router>
  );
}

export default Navigation;
