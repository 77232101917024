import React, { useState, useEffect } from "react";
import "./msg.css";
const ChatList = ({ selectedChat, setSelectedChat, id }) => {
  const [chatUsers, setChatUsers] = useState([]);
  const userId = id;
  useEffect(() => {
    async function fetchChatUsers() {
      try {
        const response = await fetch(`getChatUsers.php?userId=${userId}`);
        if (response.ok) {
          const data = await response.json();
          setChatUsers(data);
          console.log(data);
        } else {
          console.error(
            "Ошибка при получении списка пользователей:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Ошибка при получении списка пользователей:", error);
      }
    }

    fetchChatUsers();
  }, [userId]);

  return (
    <section className="flex flex-col flex-none overflow-auto w-24 hover:w-64 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out bg-purple-200">
      <div className="contacts p-2 flex-1 overflow-y-auto">
        {chatUsers.map((user, index) => (
          <div
            key={index}
            className={`flex justify-between items-center p-3 hover:bg-purple-400 rounded-lg relative ${
              selectedChat === user.chat_user_id ? "bg-blue-500" : ""
            } cursor-pointer`}
            onClick={() => setSelectedChat(user.chat_user_id)}
          >
            <div className="w-16 h-16 relative flex flex-shrink-0">
              <img
                className="shadow-md rounded-full w-full h-full object-cover"
                src={user.photo}
                alt=""
              />
            </div>
            <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block">
              <p className="text-lg">{user.chat_user_name}</p>
              <div className="flex items-center text-sm text-gray-600">
                <div className="min-w-0">
                  <p className="truncate">{user.last_message}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChatList;
