import React, { useState } from "react";
import Cookies from "js-cookie";

function PhotoDeletion({ data, onDeletePhotos, save }) {
  const id = JSON.parse(Cookies.get("Cookie")).id;
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const folder = `users/user_${id}/photos`;

  const handleTogglePhoto = (photoName) => {
    if (selectedPhotos.includes(photoName)) {
      setSelectedPhotos(selectedPhotos.filter((name) => name !== photoName));
    } else {
      setSelectedPhotos([...selectedPhotos, photoName]);
    }
  };
  const handleDeleteActive = () => {
    onDeletePhotos();
    setSelectedPhotos([]);
  };
  const handleDeleteSend = () => {
    // Создаем объект с данными для отправки
    const Data = {
      id: id,
      selectedPhotos: selectedPhotos,
    };

    // Отправляем объект на сервер
    fetch("deletephoto.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Data),
    })
      .then((response) => {
        if (response.ok) {
          // Успешно удалено на сервере, теперь можно удалить из состояния
          setSelectedPhotos([]);
          onDeletePhotos(); // Вызвать функцию для обновления списка фотографий
          return response.json(); // Распарсить JSON-ответ
        } else {
          // Обработка ошибки, например, вывод сообщения об ошибке
          console.error("Ошибка удаления фотографий на сервере");
        }
      })
      .then((responseData) => {
        // Обновить состояние data.photo с обновленным списком фотографий
        if (responseData.photos) {
          data.photo = responseData.photos;
          save(data);
        }
      })

      .catch((error) => {
        console.error("Произошла ошибка при выполнении запроса:", error);
      });
  };

  return (
    <div className="mb-6 p-4 border rounded-md shadow-md">
      <h3 className="text-xl font-semibold mb-4">Удалить фотографии:</h3>
      <div className="flex flex-wrap -m-2">
        {data.photo &&
          data.photo.map((photoName, index) => (
            <div
              key={index}
              className={`w-1/2 p-2 relative group transform transition-transform duration-300 ease-in-out hover:scale-105`}
            >
              <input
                type="checkbox"
                className="hidden absolute top-2 right-2 z-10"
                id={`deletePhoto${index}`}
                name={`deletePhoto${index}`}
                value={photoName}
                checked={selectedPhotos.includes(photoName)}
                onChange={() => handleTogglePhoto(photoName)}
              />
              <label
                htmlFor={`deletePhoto${index}`}
                className="block relative cursor-pointer border rounded-lg border-gray-300"
              >
                <div
                  className={`w-full h-auto max-w-full rounded-lg relative overflow-hidden ${
                    selectedPhotos.includes(photoName) ? "darken" : ""
                  }`}
                >
                  <img
                    src={photoName}
                    alt={photoName}
                    className="w-full h-auto max-w-full"
                  />
                  {selectedPhotos.includes(photoName) && (
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                  )}
                </div>
              </label>
            </div>
          ))}
      </div>
      <div className="flex mt-4">
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-lg cursor-pointer mr-2 transition-transform duration-300 ease-in-out transform hover:scale-105"
          onClick={handleDeleteSend}
        >
          Удалить отмеченные фотографии
        </button>
        <button
          type="button"
          className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded-lg cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
          onClick={handleDeleteActive}
        >
          Отмена
        </button>
      </div>
    </div>
  );
}

export default PhotoDeletion;
