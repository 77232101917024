import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function SearchCriteriaModal({ onClose, id }) {
  const [ageRange, setAgeRange] = useState([18, 30]);
  const [maxDistance, setMaxDistance] = useState('');
  const [gender, setGender] = useState('');

  const handleAgeRangeChange = (values) => {
    setAgeRange(values);
  };
  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetch(`criterialmodal.php?id=${id}`);
        if (response.ok) {
          const data = await response.json();
          // Здесь предполагается, что данные с сервера имеют структуру, которая соответствует вашим состояниям.
          // Например, если данные содержат ageRange, maxDistance и gender, то можно установить их значения следующим образом:

          setAgeRange([data.minAge, data.maxAge]);
          setMaxDistance(data.maxDistance);
          setGender(data.gender);
        } else {
          console.error('Error fetching profile data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    fetchProfileData();
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('id', id);
    formData.append('maxDistance', maxDistance);
    formData.append('minAge', ageRange[0]);
    formData.append('maxAge', ageRange[1]);
    formData.append('gender', gender);

    try {
      const response = await fetch('search.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Обработка успешного ответа
        const data = await response.json();
        console.log(data);
      } else {
        // Обработка ошибки
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      // Обработка ошибки сети
      console.error('Ошибка сети', error);
    }
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <form
        className="bg-white p-6 rounded-lg shadow-md w-96 max-w-full items-center"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-semibold mb-4">Критерии поиска</h2>

        <label className="block mb-2">
          Максимальное расстояние(км):
          <input
            type="number"
            className="border rounded p-1 "
            value={maxDistance}
            onChange={(e) => setMaxDistance(e.target.value)}
          />
        </label>

        <label className="block mb-2">
          Возраст:
          <Slider
            range
            min={0}
            max={80}
            step={1}
            value={ageRange}
            onChange={handleAgeRangeChange}
            className="block w-full mt-1"
            trackStyle={{ backgroundColor: "hotpink", height: 10 }}
            railStyle={{ backgroundColor: "orchid", height: 10 }}
            handleStyle={{
            borderColor: "deepskyblue",
            height: 20,
            width: 20,
            marginLeft: -10,
            marginTop: -5,
            backgroundColor: "deepskyblue"
        }}
          />
          <div className="flex justify-between">
            <span>{ageRange[0]}</span>
            <span>{ageRange[1]}</span>
          </div>
        </label>

        <label className="block mb-4">
          Пол:
          <select 
            className="border-0 cursor-pointer rounded-full drop-shadow-md bg-sky-200 w-72 duration-300 hover:bg-sky-400 focus:bg-fushsia-300 py-2 ml-2"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Выберите пол</option>
            <option value="male">Мужской</option>
            <option value="female">Женский</option>
          </select>
        </label>

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Отмена
          </button>
          <button
            type="submit"
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchCriteriaModal;
