import myImage from "./Empty.png";
import next from "./321.png";
import left from "./left.svg";
import right from "./right.svg";
import like from "./heart.svg";
import edit from "./sliders.svg";
import message from "./message.svg";
import React, { useState, useEffect, useMemo } from "react";
import Cookies from "js-cookie";
import SearchCriteriaModal from "./SearchCriteriaModal";
import MessageModal from "./MessageModal";

function AnketsContent() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImage] = useState([myImage]);
  const myId = JSON.parse(Cookies.get("Cookie")).id;
  const [id, setId] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false); // Новое состояние для модального окна сообщения

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleMessageModalOpen = () => {
    setIsMessageModalOpen(true);
  };

  const handleMessageModalClose = () => {
    setIsMessageModalOpen(false);
  };

  const fetchNextProfileId = async () => {
    try {
      setCurrentImageIndex(0);
      const response = await fetch(`getid.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          myId: myId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.nextId, data.t);
        setId(data.nextId);
      } else {
        console.error("Error fetching next profile id:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching next profile id:", error);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await fetch(`copy.php?id=${id}`);
      if (response.ok) {
        const data = await response.json();
        setProfileData(data);
        setImage(data.photo);
      } else {
        console.error("Error fetching profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleLikeDislike = async (isLike) => {
    try {
      const response = await fetch(isLike ? "like.php" : "dislike.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          myId: myId,
          likeId: id,
        }),
      });

      if (response.ok) {
        console.log(isLike ? "Liked successfully" : "Disliked successfully");
        fetchNextProfileId(); // Получаем следующий id для анкеты
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchProfileData(); // Загружаем данные анкеты, если есть id
    } else {
      fetchNextProfileId(); // Получаем первый id для анкеты
    }
  }, [id]);
  const folder = useMemo(() => `users/user_${id}`, [id]);

  const showPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      setCurrentImageIndex(images.length - 1);
    }
  };

  const showNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  };

  return (
    <div className="min-h-[90vh] flex items-center justify-center">
      <div className="aspect-[2/3] w-80 relative shadow-lg rounded-lg">
        <div className="relative shadow-lg h-full rounded-t-lg ">
          <img
            src={
              folder &&
              profileData.photo &&
              profileData.photo.length &&
              images.length > 0
                ? images[currentImageIndex]
                : myImage
            }
            alt="Фотография"
            className="w-full h-full object-cover rounded-t-lg"
          />
          {images.length > 0 ? (
            <h1 className="absolute top-0 right-0 transform text-opacity-40 text-sky-300">
              {currentImageIndex + 1}/{images.length}
            </h1>
          ) : (
            <h1 className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-opacity-40 text-sky-300">
              Фотографий нет
            </h1>
          )}
          <button
            onClick={showPreviousImage}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-opacity-50 bg-sky-300 hover:bg-opacity-90 text-white  rounded-md w-6 h-28 flex items-center justify-center"
          >
            <img src={left} alt="L" className="object-cover" />
          </button>
          <button
            onClick={showNextImage}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-opacity-50 bg-sky-300 hover:bg-opacity-90 text-white   rounded-md w-6 h-28 flex items-center justify-center"
          >
            <img src={right} alt="R" className="object-cover" />
          </button>
          <div className="absolute bottom-0 w-full text-white text-left bg-gradient-to-b from-transparent to-slate-950 rounded-t-lg p-4">
            <h2 className="text-xl font-bold">
              {profileData.name} {profileData.age}
            </h2>
            <p className="text-sm">{profileData.facts}</p>
          </div>
        </div>
        <div className="bg-slate-950  bottom-0 left-0 w-full flex justify-center space-x-4 rounded-b-lg py-2">
          <button
            className="bg-blue-500 text-white rounded-full hover:bg-blue-600 transition w-8 h-8 relative flex items-center justify-center"
            onClick={handleModalOpen}
          >
            <img
              src={edit}
              alt="Редактировать условия поиска"
              className="object-cover"
            />
          </button>
          <button
            className="bg-pink-500 hover:bg-pink-600 text-white rounded-full w-14 h-14 relative flex items-center justify-center"
            onClick={() => handleLikeDislike(true)}
          >
            <img src={like} alt="Нравится!" className="object-cover" />
          </button>
          <button
            className="bg-gradient-to-r from-pink-500 to-purple-500 hover:from-pink-600 hover:to-purple-600 text-white rounded-full w-14 h-14 relative flex items-center justify-center"
            onClick={handleMessageModalOpen}
          >
            <img
              src={message}
              alt="Отправить сообщение"
              className="object-cover"
            />
          </button>
          <button
            className="rounded-full bg-purple-500 hover:bg-purple-600 w-14 h-14 relative flex items-center justify-center"
            onClick={() => handleLikeDislike(false)}
          >
            <img src={next} alt="Пропустить" className="object-cover" />
          </button>
          <button
            className="bg-blue-500 text-white rounded-full hover:bg-blue-600 transition w-8 h-8 relative flex items-center justify-center"
            onClick={handleModalOpen}
          >
            <img
              src={edit}
              alt="Редактировать условия поиска"
              className="object-cover"
            />
          </button>
        </div>
      </div>
      {isMessageModalOpen && (
        <MessageModal
          onClose={handleMessageModalClose}
          recipientId={id}
          id={myId}
        />
      )}
      {isModalOpen && (
        <SearchCriteriaModal onClose={handleModalClose} id={myId} />
      )}
    </div>
  );
}

export default AnketsContent;
