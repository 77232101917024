import React, { useState } from 'react';
import ChatList from './Messager/ChatList';
import ChatWindow from './Messager/ChatWindow';
import Cookies from 'js-cookie';
import backgroundImage from './5.jpg'; // Импорт изображения

function MessageContent() {
  const [selectedChat, setSelectedChat] = useState(null);
  const id = JSON.parse(Cookies.get('Cookie')).id;

  const chatContent = selectedChat ? (
    <ChatWindow selectedChat={selectedChat} id={id} />
  ) : (
    <img
      src={backgroundImage}
      alt="Background"
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
    />
  );

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  /* can be configured in tailwind.config.js */\n  .group:hover .group-hover\\:block {\n    display: block;\n  }\n  .hover\\:w-64:hover {\n    width: 45%;\n    }\n  \n  \n  /* NO NEED THIS CSS - just for custom scrollbar which can also be configured in tailwind.config.js*/"
        }}
      />
      <div className="h-[91vh] w-full flex antialiased text-black-100 bg-purple-100 overflow-hidden">
        <div className="flex-1 flex flex-col">
          <main className="flex-grow flex flex-row min-h-0">
            <ChatList selectedChat={selectedChat} setSelectedChat={setSelectedChat} id={id}/>
            {chatContent}
          </main>
        </div>
      </div>
    </>
  );
}

export default MessageContent;
