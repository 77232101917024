import React, { useState, useEffect, useRef } from "react";
function ChatWindow({ selectedChat, id }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [userName, setUserName] = useState(""); // Состояние для имени пользователя
  const [userPhoto, setUserPhoto] = useState("");
  const [myPhoto, setmyPhoto] = useState("");
  console.log(id);
  const chatEndRef = useRef(null);

  useEffect(() => {
    // Прокрутка вниз при загрузке страницы или обновлении сообщений
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userResponse = await fetch(`getuser.php?id=${selectedChat}`);
        if (userResponse.ok) {
          const userData = await userResponse.json();
          console.log(userData);
          setUserName(userData.userName); // Обновляем состояние с именем пользователя
          setUserPhoto(userData.userPhoto);
        } else {
          console.error("Error fetching user info");
        }
      } catch (error) {
        console.error("Error fetching user info", error);
      }
    };

    fetchUserInfo();
  }, [selectedChat]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(
          `getchat.php?selectedChat=${selectedChat}&id=${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setMessages(data); // Обновляем состояние messages данными с сервера
          console.log(data);
        } else {
          console.error("Error fetching messages");
        }
      } catch (error) {
        console.error("Error fetching messages", error);
      }
    };

    fetchMessages();

    const intervalId = setInterval(fetchMessages, 10000);

    // Очистка интервала при размонтировании компонента
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedChat, id]);
  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetch(`copy.php?id=${id}`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setmyPhoto(data.photo[0]);
        } else {
          console.error("Error fetching profile data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    }

    fetchProfileData();
  }, [id]);
  return (
    <section className="flex flex-col flex-auto border-l border-gray-800 ">
      <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center shadow">
        <div className="flex">
          <div className="w-12 h-12 mr-4 relative flex flex-shrink-0">
            <img
              className="shadow-md rounded-full w-full h-full object-cover"
              src={userPhoto}
              alt=""
            />
          </div>
          <div className="text-sm">
            <p className=" text-lg">{userName}</p>
          </div>
        </div>
      </div>
      <div className="chat-body p-4 flex-1 overflow-y-scroll">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.sender_id === id.toString()
                ? "justify-end"
                : "justify-start"
            } my-2`}
          >
            {message.sender_id !== id.toString() && (
              <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
                <img
                  className="shadow-md rounded-full w-full h-full object-cover"
                  src={userPhoto}
                  alt=""
                />
              </div>
            )}
            <div
              className={`messages ${
                message.sender_id === id.toString()
                  ? "text-white"
                  : "text-gray-700"
              } grid grid-flow-row gap-2`}
            >
              <div
                className={`flex items-center ${
                  message.sender_id === id.toString()
                    ? "flex-row-reverse"
                    : "group"
                }`}
              >
                <h2
                  className={`px-6 py-3 rounded-t-full ${
                    message.sender_id === id.toString()
                      ? "rounded-l-full bg-sky-400"
                      : "rounded-r-full bg-red-400"
                  } max-w-xs lg:max-w-md text-base lg:text-lg ${
                    message.sender_id === id.toString()
                      ? "text-purple-950"
                      : "text-purple-950"
                  }`}
                >
                  {message.message_text}
                </h2>
              </div>
            </div>

            {message.sender_id === id.toString() && (
              <div className="w-8 h-8 relative flex flex-shrink-0 ml-4">
                <img
                  className="shadow-md rounded-full w-full h-full object-cover"
                  src={myPhoto}
                  alt=""
                />
              </div>
            )}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      <div className="chat-footer flex-none">
        <div className="flex flex-row items-center p-4">
          <button
            type="button"
            className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-600 hover:text-blue-700 w-6 h-6"
          >
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M11,13 L8,10 L2,16 L11,16 L18,16 L13,11 L11,13 Z M0,3.99406028 C0,2.8927712 0.898212381,2 1.99079514,2 L18.0092049,2 C19.1086907,2 20,2.89451376 20,3.99406028 L20,16.0059397 C20,17.1072288 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M15,9 C16.1045695,9 17,8.1045695 17,7 C17,5.8954305 16.1045695,5 15,5 C13.8954305,5 13,5.8954305 13,7 C13,8.1045695 13.8954305,9 15,9 Z" />
            </svg>
          </button>
          <button
            type="button"
            className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-600 hover:text-blue-700 w-6 h-6"
          >
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M9,18 L9,16.9379599 C5.05368842,16.4447356 2,13.0713165 2,9 L4,9 L4,9.00181488 C4,12.3172241 6.6862915,15 10,15 C13.3069658,15 16,12.314521 16,9.00181488 L16,9 L18,9 C18,13.0790094 14.9395595,16.4450043 11,16.9378859 L11,18 L14,18 L14,20 L6,20 L6,18 L9,18 L9,18 Z M6,4.00650452 C6,1.79377317 7.79535615,0 10,0 C12.209139,0 14,1.79394555 14,4.00650452 L14,8.99349548 C14,11.2062268 12.2046438,13 10,13 C7.790861,13 6,11.2060545 6,8.99349548 L6,4.00650452 L6,4.00650452 Z" />
            </svg>
          </button>
          <div className="relative flex-grow">
            <input
              className="rounded-full py-2 pl-3 pr-10 w-full  bg-slate-50"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Aa"
            />
          </div>
          <button
            type="button"
            className="flex flex-shrink-0 focus:outline-none mx-2 block text-blue-600 hover:text-blue-700 w-6 h-6"
            onClick={async () => {
              // Отправка сообщения
              if (inputMessage.trim() !== "") {
                try {
                  const response = await fetch("firstmessage.php", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `recipientId=${selectedChat}&message=${encodeURIComponent(
                      inputMessage
                    )}&senderId=${id}`,
                  });

                  if (response.ok) {
                    const data = await response.json();
                    console.log(data.message);

                    // Обновление списка сообщений в локальном состоянии
                    const newMessage = {
                      sender_id: id.toString(),
                      message_text: inputMessage,
                      recipient_id: selectedChat.toString(),
                    };
                    setMessages([...messages, newMessage]);

                    // Очистка поля ввода
                    setInputMessage("");
                  } else {
                    console.error(
                      "Ошибка при отправке сообщения:",
                      response.statusText
                    );
                  }
                } catch (error) {
                  console.error("Ошибка при отправке сообщения:", error);
                }
              }
            }}
          >
            <svg viewBox="0 0 20 20" className="w-full h-full fill-current">
              <path d="M11.0010436,0 C9.89589787,0 9.00000024,0.886706352 9.0000002,1.99810135 L9,8 L1.9973917,8 C0.894262725,8 0,8.88772964 0,10 L0,12 L2.29663334,18.1243554 C2.68509206,19.1602453 3.90195042,20 5.00853025,20 L12.9914698,20 C14.1007504,20 15,19.1125667 15,18.000385 L15,10 L12,3 L12,0 L11.0010436,0 L11.0010436,0 Z M17,10 L20,10 L20,20 L17,20 L17,10 L17,10 Z" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}
export default ChatWindow;
