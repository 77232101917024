import React, { useEffect, useState } from "react";
import ProfileEditForm from "./ProfileEditForm";
import Cookies from "js-cookie";
import myImage from "./Empty.png";

function HomeContent({ locationState }) {
  const id = JSON.parse(Cookies.get("Cookie")).id;
  const folder = `users/user_${id}`;
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await fetch(`copy.php?id=${id}`);
        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
        } else {
          console.error("Error fetching profile data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    }

    fetchProfileData();
  }, [id]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleFormSave = (data) => {
    setProfileData(data);
  };

  const handleFormClose = () => {
    setIsEditing(false);
  };

  return (
    <div className="mx-auto p-0 md:p-0 mb-20 overflow-y-auto h-[88vh]">
      <section className="container mx-auto mt-8 px-4">
        <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">
          <div className="w-full md:w-1/3">
            <img
              src={
                folder && profileData.photo && profileData.photo.length > 0
                  ? profileData.photo[0]
                  : myImage
              }
              alt="Аватар"
              className="w-40 h-40 rounded-full mx-auto"
            />
          </div>

          <div className="w-full md:w-2/3">
            <h1 className="text-4xl font-bold text-center md:text-left mb-2">
              {profileData.name}
            </h1>
            <h2 className="text-xl text-center md:text-left mb-1">
              Возраст: {profileData.age}
            </h2>
            <p className="text-lg text-center md:text-left">{locationState}</p>
            <p className="text-lg mt-2 text-center md:text-left">
              {profileData.facts}
            </p>
            <div className="flex justify-center md:justify-start mt-4">
              <button
                className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition"
                onClick={handleEditClick}
              >
                Редактировать профиль
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="container mx-auto mt-8 px-4">
        <div className="flex items-center justify-between">
          <h2 className="text-3xl font-bold mb-4 mr-4">Мои фотографии</h2>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {profileData.photo && profileData.photo.length > 0
            ? profileData.photo.map((photoName, index) => (
                <img
                  key={index}
                  src={photoName}
                  alt={`Фото ${index + 1}`}
                  className="w-full h-full rounded-lg"
                />
              ))
            : Array(4)
                .fill()
                .map((_, index) => (
                  <img
                    key={index}
                    src={myImage}
                    alt={`Фото ${index + 1}`}
                    className="w-full h-full rounded-lg"
                  />
                ))}
        </div>
      </section>
      {isEditing && (
        <ProfileEditForm
          data={profileData}
          id={id}
          onClose={handleFormClose}
          onSave={handleFormSave}
        />
      )}
    </div>
  );
}

export default HomeContent;
