import React, { useState } from 'react';

function MessageModal({ onClose, recipientId, id }) {
  const [message, setMessage] = useState('');

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await fetch('firstmessage.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `recipientId=${recipientId}&message=${message}&senderId=${id}`,
      });
      

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // Закрыть модальное окно после успешной отправки
        onClose();
      } else {
        console.error('Ошибка при отправке сообщения:', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-gradient-to-r from-pink-300 to-purple-500 p-8 rounded-lg shadow-lg w-80">
        <h1 className="text-3xl font-semibold mb-6 text-white">Отправить сообщение</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full p-2 bg-gray-100 rounded-lg mb-4"
            rows="4"
            placeholder="Введите ваше сообщение..."
            value={message}
            onChange={handleMessageChange}
          />
          <div className="flex justify-end">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition mr-2"
              type="submit"
            >
              Отправить
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full hover:bg-gray-400 transition"
              onClick={onClose}
            >
              Закрыть
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MessageModal;
