import React, { useState } from "react";
import axios from "axios"; // Импорт Axios
import Cookies from "js-cookie"; // Импорт js-cookie
function RegistrationModal({ isOpen, onClose, onRegistrationSuccess }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("check.php", formData);

      console.log(response.data); // Обработка ответа от сервера
      switch (response.data.message) {
        case "Вход":
          Cookies.set("Cookie", JSON.stringify(response.data));
          onRegistrationSuccess(response.data);
          break;
        default:
          setError(response.data.message);
          break;
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div id="myModal" className="modal" style={{ display: "block" }}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h1 className="text-xl text-blue-500 mb-5">Регистрация</h1>
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <label htmlFor="firstName">Имя:</label>
          <input
            type="text"
            id="firstName"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          <label htmlFor="email">Электронная почта:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="password">Пароль:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <div className="button-group">
            <input
              type="submit"
              value="Зарегистрироваться"
              className="register-button"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegistrationModal;
