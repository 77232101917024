import React, {useState} from 'react';
import axios from 'axios'; // Импорт Axios
import Cookies from 'js-cookie'; // Импорт js-cookie
function LoginModal({ isOpen, onClose, onLoginSuccess }) {
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('login.php', loginFormData);
      console.log(response.data); // Обработка ответа от сервера
      if (response.data.message === 'Вход') {
        Cookies.set('Cookie', JSON.stringify(response.data));
        onLoginSuccess(response.data); // Вызываем колбэк при успешном входе
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div id="loginModal" className="modal" style={{ display: 'block' }}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h1 className="text-xl text-blue-500 mb-5">Вход</h1>
        <form onSubmit={handleLoginSubmit}>
        {error && <p className="error-message">{error}</p>}
  <label htmlFor="loginEmail">Электронная почта:</label>
  <input
    type="text"
    id="loginEmail"
    name="email"
    value={loginFormData.email}
    onChange={handleLoginChange}
    required
  />
  <label htmlFor="loginPassword">Пароль:</label>
  <input
    type="password"
    id="loginPassword"
    name="password"
    value={loginFormData.password}
    onChange={handleLoginChange}
    required
  />
  <div className="button-group">
    <input type="submit" value="Войти" className="login-button" />
  </div>
</form>
        <div className="forgot-password">
          <a href="forgot_password.php">Забыли пароль?</a>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
