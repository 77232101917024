import React, { useState } from 'react';
import PhotoDeletion from './PhotoDeletion';
import AvaChoose from './AvaChoose'
function ProfileEditForm({ data, id, onClose, onSave }) {
    const [profileData, setProfileData] = useState(data);
    const [selectedFiles, setSelectedFiles] = useState([]);// По умолчанию пол может быть пустым
    const [isPhotoDeletionOpen, setPhotoDeletionOpen] = useState(false);
    const [isAvaChooseOpen, setisAvaChooseOpen] = useState(false);
    
    const closeAvaChoose = () => {
        setisAvaChooseOpen(false);
    };
    const openAvaChoose = () => {
        setisAvaChooseOpen(true);
        setPhotoDeletionOpen(false);
    };
    const openPhotoDeletion = () => {
        setPhotoDeletionOpen(true);
        setisAvaChooseOpen(false);
    };

    const closePhotoDeletion = () => {
        setPhotoDeletionOpen(false);
    };
    

    const handleChange = (field, value) => {
        setProfileData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleFilesChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', profileData.name);
        formData.append('age', profileData.age);
        formData.append('facts', profileData.facts);
        formData.append('gender', profileData.gender);

        selectedFiles.forEach((file, index) => {
            formData.append(`profileImage${index}`, file);
        });

        try {
            const response = await fetch('update_profile.php', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const responseData = await response.json();
                const uploadedPhotoNames = responseData.message; // Получение списка имен файлов
    
                // Далее вы можете использовать этот список, например, для вывода их на странице
                console.log('Загруженные имена файлов:', uploadedPhotoNames);
                profileData.photo=uploadedPhotoNames;
                onSave(profileData);
                onClose()
            } else {
                console.error('Ошибка при отправке данных на сервер');
            }
        } catch (error) {
            console.error('Произошла ошибка:', error);
        }
    };

    return (
        
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md max-h-[90vh] w-96 overflow-y-auto">
                <h2 className="text-2xl font-semibold mb-4">Редактирование профиля</h2>
                <div className="mb-4">
                    <label htmlFor="name" className="block font-medium">
                        Имя:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="w-full px-3 py-2 border rounded"
                        value={profileData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                </div>
                <div className="mb-4">
    <label htmlFor="age" className="block font-medium">
        Возраст:
    </label>
    <select
        id="age"
        name="age"
        className="w-full px-3 py-2 border rounded"
        value={profileData.age}
        onChange={(e) => handleChange('age', e.target.value)}
    >
        {[...Array(100).keys()].map((num) => (
            <option key={num} value={num}>
                {num}
            </option>
        ))}
    </select>
</div>
<div className="mb-4">
    <label htmlFor="gender" className="block font-medium">
        Пол:
    </label>
    <select
        id="gender"
        name="gender"
        className="w-full px-3 py-2 border rounded"
        value={profileData.gender}
        onChange={(e) => handleChange('gender', e.target.value)}
    >
        <option value="">Выберите пол</option>
        <option value="male">Мужской</option>
        <option value="female">Женский</option>
        <option value="other">Другой</option>
    </select>
</div>

                <div className="mb-4">
                    <label htmlFor="facts" className="block font-medium">
                        Факты о себе:
                    </label>
                    <textarea
                        id="facts"
                        name="facts"
                        className="w-full px-3 py-2 border rounded"
                        rows="4"
                        value={profileData.facts}
                        onChange={(e) => handleChange('facts', e.target.value)}
                    />
                </div>
                <div className="mb-6 ">
    <label htmlFor="profileImages" className="block font-medium mb-2">
        Редактировать фотографии:
    </label>
   {(profileData.photo.length>1) &&(<button
            type="button"
            className="mb-2 mx-auto flex items-stretch bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            onClick={openAvaChoose}
     >
            Выбрать аватарку
        </button>)} 
        {isAvaChooseOpen && (
    <AvaChoose data={data} photos={profileData.photo} onAvaChoose={closeAvaChoose} save={onSave}/>
)}
    <div className='flex items-center'>
        <label
            htmlFor="profileImages"
            className="bg-pink-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-pink-300 inline-block mr-3 text-center"
        >
            Загрузить фотографии
        </label>
        <input
            type="file"
            id="profileImages"
            name="profileImages"
            accept="image/*"
            multiple
            onChange={handleFilesChange}
            className="hidden"
        />
        <button
            type="button"
            className="bg-blue-700 text-white px-4 py-2 rounded cursor-pointer hover:bg-blue-500 text-center ml-1"
            onClick={openPhotoDeletion}
     >
            Удалить фотографии
        </button>
    </div>
    {selectedFiles.length > 0 && (
    <div className="flex flex-wrap justify-center">
        {selectedFiles.map((file, index) => (
            <div key={index} className="w-1/3 p-2">
                <img
                    src={URL.createObjectURL(file)}
                    alt={`Фотография ${index + 1}`}
                    className="max-w-full h-auto"
                />
            </div>
        ))}
    </div>
)}
</div>


{isPhotoDeletionOpen && (
    <PhotoDeletion data={data} photos={profileData.photo} onDeletePhotos={closePhotoDeletion} save={onSave}/>
)}
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
                        onClick={onClose}
                    >
                        Отмена
                    </button>
                    <button
                        type="submit"
                        className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
                    >
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ProfileEditForm;