import React, { useState } from 'react';
import './styles_in.css'; // Импорт стилей
import backgroundImage from './5.jpg'; // Импорт изображения
import RegistrationModal from './RegistrationModal'; // Импорт компонента регистрации
import LoginModal from './LoginModal'; // Импорт компонента входа
import Navigation from './Navigation';

function Input() {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [showNavigation, setShowNavigation] = useState(false);
  
  const handleRegistrationSuccess = (data) => {
    setRegistrationData(data);
    setShowRegistrationModal(false);
    setShowNavigation(true);
  };

  const handleLoginSuccess = (data) => {
    setLoginData(data);
    setShowLoginModal(false);
    setShowNavigation(true);
  };

  const openRegistrationModal = () => {
    setShowRegistrationModal(true);
    setShowLoginModal(false);
  };

  const closeRegistrationModal = () => {
    setShowRegistrationModal(false);
  };

  const openLoginModal = () => {
    setShowLoginModal(true);
    setShowRegistrationModal(false);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    <div>
      {!showNavigation ? (
        <div>
          <div className="fullscreen-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <button className="button" onClick={openRegistrationModal}>Присоединиться</button>
          </div>

          <RegistrationModal
            isOpen={showRegistrationModal}
            onClose={closeRegistrationModal}
            onRegistrationSuccess={handleRegistrationSuccess}
          />

          <button className="round-button" onClick={openLoginModal}>Войти</button>

          <LoginModal
            isOpen={showLoginModal}
            onClose={closeLoginModal}
            onLoginSuccess={handleLoginSuccess}
          />
        </div>
      ) : (
        <Navigation />
      )}
    </div>
  );
}

export default Input;
