import React, { useState } from "react";
import Cookies from "js-cookie";

function PhotoDeletion({ data, onAvaChoose, save }) {
  const id = JSON.parse(Cookies.get("Cookie")).id;
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const folder = `users/user_${id}/photos`;

  const handleTogglePhoto = (photoName) => {
    if (selectedPhoto === photoName) {
      setSelectedPhoto(null);
    } else {
      setSelectedPhoto(photoName);
    }
  };

  const handleDeleteActive = () => {
    onAvaChoose();
    setSelectedPhoto(null);
  };

  const handleDeleteSend = () => {
    if (!selectedPhoto) {
      console.error("Выберите фотографию для удаления");
      return;
    }

    // Создаем объект с данными для отправки
    const dataToSend = {
      id: id,
      selectedPhoto: selectedPhoto,
    };

    // Отправляем объект на сервер
    fetch("chooseAva.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.ok) {
          // Успешно удалено на сервере, теперь можно удалить из состояния
          setSelectedPhoto(null);
          onAvaChoose(); // Вызвать функцию для обновления списка фотографий
          return response.json(); // Распарсить JSON-ответ
        } else {
          // Обработка ошибки, например, вывод сообщения об ошибке
          console.error("Ошибка удаления фотографии на сервере");
        }
      })
      .then((responseData) => {
        // Обновить состояние data.photo с обновленным списком фотографий
        if (responseData.photos) {
          data.photo = responseData.photos;
          save(data);
        }
      })
      .catch((error) => {
        console.error("Произошла ошибка при выполнении запроса:", error);
      });
  };

  return (
    <div className="mb-6 p-4 border rounded-md shadow-md">
      <h3 className="text-xl font-semibold mb-4">Фотографии:</h3>
      <div className="flex flex-wrap -m-2">
        {data.photo &&
          data.photo.map((photoName, index) => (
            <div
              key={index}
              className={`w-1/2 p-2 relative group transform transition-transform duration-300 ease-in-out hover:scale-105`}
            >
              <input
                type="radio"
                className="hidden absolute top-2 right-2 z-10"
                id={`deletePhoto${index}`}
                name="selectedPhoto"
                value={photoName}
                checked={selectedPhoto === photoName}
                onChange={() => handleTogglePhoto(photoName)}
              />
              <label
                htmlFor={`deletePhoto${index}`}
                className="block relative cursor-pointer border rounded-lg border-gray-300"
              >
                <div
                  className={`w-full h-auto max-w-full rounded-lg relative overflow-hidden ${
                    selectedPhoto === photoName ? "brighten" : ""
                  }`}
                >
                  <img
                    src={photoName}
                    alt={photoName}
                    className={`w-full h-auto max-w-full ${
                      selectedPhoto === photoName ? "brightness-110" : ""
                    }`}
                  />
                </div>
              </label>
            </div>
          ))}
      </div>
      <div className="flex mt-4">
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded-lg cursor-pointer mr-2 transition-transform duration-300 ease-in-out transform hover:scale-105"
          onClick={handleDeleteSend}
        >
          Выбрать
        </button>
        <button
          type="button"
          className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 rounded-lg cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
          onClick={handleDeleteActive}
        >
          Отмена
        </button>
      </div>
    </div>
  );
}

export default PhotoDeletion;
